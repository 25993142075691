import React from "react"
import { Link } from "gatsby"
import "./post-list.scss"

export const PostList = ({ posts }) => {
  return (
    <ol className="post-list">
      {posts.map(post => {
        const title = post.frontmatter.title || post.fields.slug

        return (
          <li key={post.fields.slug}>
            <article
              className="post-list__item"
              itemScope
              itemType="http://schema.org/Article"
            >
              <header>
                <h2 className="post-list__title">
                  <Link to={post.fields.slug} itemProp="url">
                    <span itemProp="headline">{title}</span>
                  </Link>
                </h2>
              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: post.frontmatter.description || post.excerpt,
                  }}
                  itemProp="description"
                />
              </section>
              <footer>
                <small>{post.frontmatter.date}</small>
              </footer>
            </article>
          </li>
        )
      })}
    </ol>
  )
}
